import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@mui/material';

/**+
 *
 * @param {string} type Type name, possibly followed by : and comma-separated choices.
 * @returns A tuple of the type name plus a possible empty set of choices.
 */
function SplitType(type) {
  const elements = type.split(':');
  const baseType = elements[0];
  let choices = [];
  if (elements.length > 1) {
    choices = elements[1].split(',');
  }

  return [baseType, choices];
}

const SettingField = ({
  type, setting, choices, onChange,
}) => (
  <TextField
    type={type}
    fullWidth
    margin='normal'
    sx={{ marginY: 4 }}
    label={setting.prompt ?? setting.name}
    helperText={setting.description}
    placeholder='Value'
    defaultValue={setting.value}
    onChange={(e) => onChange(setting.name, e?.target.value)}
    select={choices?.length > 0}
    error={type === 'unknown'}
    InputProps={type === 'unknown' ? { readOnly: true } : null}
  >
    {choices?.map((choice) => (
      <MenuItem key={choice} value={choice}>{choice}</MenuItem>
    ))}
  </TextField>
);

SettingField.propTypes = {
  type: PropTypes.string,
  setting: PropTypes.any.isRequired,
  choices: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

/**
 * Create and return elements for displaying and editing a setting.
 * Booleans are currently supported as true/false dropdowns.
 * Later improvements may add checkboxes or radio buttons. Or both.
 *
 * @param {object} setting The setting description.
 * @param {func} onChange Callback for setting changes.
 * @returns Elements for displaying and editing a setting.
 */
const Setting = ({ setting, onChange }) => {
  const [baseType, choices] = SplitType(setting.type);
  switch (baseType) {
    case 'String':
      return <SettingField
        setting={setting}
        onChange={onChange}
      />;
    case 'Number':
      return <SettingField
        setting={setting}
        onChange={onChange}
        type='number'
      />;
    case 'Choice':
      return <SettingField
        setting={setting}
        onChange={onChange}
        choices={choices}
      />;
    case 'Boolean':
      return <SettingField
        setting={setting}
        onChange={onChange}
        choices={['true', 'false']}
      />;
    default:
      return <SettingField
        setting={setting}
        onChange={onChange}
        type='unknown'
      />;
  }
};

Setting.propTypes = {
  setting: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Setting;
