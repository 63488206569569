import { Stack } from '@mui/material';
import logoSpinner from '../../assets/logoSpinner.svg';

const Loading = () => (
  <Stack justifyContent='center' alignItems='center' minHeight='80vh'>
    <img src={logoSpinner} height={30} width={30} alt="Loading..." />
  </Stack>
);

export default Loading;
