import PropTypes from 'prop-types';
import { TextField, MenuItem } from '@mui/material';
import { useRoleCheck } from '../api/authorization';

export const defaultBundleCategory = 'None';

const BundleCategory = ({
  defaultCategory, releaseRole, current, onChange, ...props
}) => {
  const availableCategories = !releaseRole || useRoleCheck(releaseRole)
    ? [defaultCategory, 'Yellow', 'Red', 'Green']
    : [defaultCategory];

  return (
    <TextField
      label='Category'
      select
      value={current}
      disabled={availableCategories.length < 2 && !current}
      onChange={(e) => onChange(e.target.value)}
      {...props}
    >
      {availableCategories.map((c) => <MenuItem key={c} value={c}>{c}</MenuItem>)}
    </TextField>
  );
};

BundleCategory.propTypes = {
  defaultCategory: PropTypes.string,
  releaseRole: PropTypes.string,
  current: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

BundleCategory.defaultProps = {
  defaultCategory: defaultBundleCategory,
};

export default BundleCategory;
