import Loading from '../ui/loading';
import Problem from '../ui/problem';

export default function loadingOrError(error) {
  if (error) {
    console.log(`error: ${JSON.stringify(error)}`); // eslint-disable-line
    return <Problem message={error.data?.title} status={error.status} />;
  }
  return (
    <Loading />
  );
}
