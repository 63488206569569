import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Grid, Link, SvgIcon, Typography,
} from '@mui/material';
import { useRoleCheck } from '../api/authorization';
import { useGetBundleQuery } from '../api/package-manager-api';
import logoSpinner from '../../assets/logoSpinner.svg';
import { ReactComponent as UpdateIcon } from '../../assets/update.svg';
import BundleContentsList from '../ui/bundle-contents-list';
import Section from './section';

function isEqualBundle(bundle1, bundle2) {
  return bundle1?.name === bundle2?.name && bundle1?.version === bundle2?.version;
}

const Bundle = ({
  bundle, isCurrent, isRunning, installation, updateManifest,
}) => {
  const userCanUpdate = useRoleCheck('installation-manager');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { data: bundleData } = useGetBundleQuery({ name: bundle?.name, version: bundle?.version });

  const cancelUpdateManifest = () => {
    setConfirmDialogOpen(false);
  };
  const handleUpdateNow = () => {
    setConfirmDialogOpen(true);
  };
  const confirmUpdateManifest = () => {
    if (!isUpdating) {
      setIsUpdating(true);
      updateManifest(
        bundle,
      );
    }
  };

  let updateText = 'Update\u00A0Now';
  if (isUpdating) updateText = 'Updating...';
  else if (bundle?.lastRun) updateText = 'Rollback';

  return (
    <Section>
      <Dialog
        open={confirmDialogOpen}
        onClose={cancelUpdateManifest}
        aria-labelledby='confirm-dialog-title'
        aria-describedby='confirm-dialog-description'>
        <DialogTitle id='confirm-dialog-title'>Update {installation.displayName}?</DialogTitle>
        <DialogContent>
          <DialogContentText id='confirm-dialog-description'>
            Please ensure that the system is stopped and no robots are moving!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelUpdateManifest} variant='contained' color='subdued'>Cancel</Button>
          <Button onClick={confirmUpdateManifest} variant='contained' color='error' autoFocus>Update</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid container item>
          <Grid item xs={8}>
            <Typography variant='h7' fontWeight={600}>{bundleData ? bundleData.displayName : bundle?.name}</Typography>
          </Grid>
          <Grid item xs={4} alignSelf='flex-end'>
            <Link to={`/bundles/${bundle?.name}/${bundle?.version}`} component={RouterLink}>
              <Typography align='right' variant='subtitle1'>Version&nbsp;{bundle?.version}</Typography>
            </Link>

          </Grid>
          <Grid container item>
            {bundleData
              && <Grid item xs={12}>
                <BundleContentsList className='text-secondary' bundles={bundleData.bundles} packages={bundleData.packages} />
              </Grid>}
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              {isCurrent && !isRunning
                && <Button variant='outlined' color='error' fullWidth disabled>
                  <span className='pe-1'><img src={logoSpinner} height={20} width={20} alt="" /></span>
                  {' '}Installation in progress...
                </Button>
              }
              {isCurrent && isRunning
                && <Button variant='outlined' color='error' disabled sx={{ width: '22ex' }}>Installed</Button>}
              {!isCurrent
                && <Button variant='contained' startIcon={<SvgIcon component={UpdateIcon} inheritViewBox />}
                  sx={{ width: '22ex' }}
                  disabled={isUpdating || !userCanUpdate}
                  onClick={!isUpdating ? handleUpdateNow : null}>
                  {updateText}
                </Button>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};

Bundle.propTypes = {
  bundle: PropTypes.any.isRequired,
  isCurrent: PropTypes.bool,
  isRunning: PropTypes.bool,
  installation: PropTypes.any.isRequired,
  updateManifest: PropTypes.func.isRequired,
};

export { Bundle, isEqualBundle };
