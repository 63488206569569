import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useAuth0, Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { apiAudience, apiScope } from './package-manager-api';
import { setAuthenticated } from './auth-slice';

export const Authenticator = ({ children }) => {
  const dispatch = useDispatch();
  const auth = useSelector((s) => s.auth);
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (auth.token == null && !isLoading) {
      setIsLoading(true);
      getAccessTokenSilently().then((token) => {
        dispatch(setAuthenticated({ user, token }));
        setIsLoading(false);
      }).catch(() => {
        logout();
      });
    }
  });
  return auth.token ? children : null;
};

export const Authenticator2000 = withAuthenticationRequired(Authenticator, {});

export const AuthProvider = ({ children, ...rest }) => {
  const propsWithDefaults = {
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authorizationParams: {
      audience: apiAudience,
      redirect_uri: window.location.origin,
      scope: apiScope,
    },
    ...rest,
  };
  return (
    <Auth0Provider {...propsWithDefaults}>
      <Authenticator2000>
        {children}
      </Authenticator2000>
    </Auth0Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

Authenticator.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
