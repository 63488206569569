import {
  Box, Stack, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

const SectionGroup = (props) => (
  <Box sx={{ marginY: '4ex' }}>
    <Typography color='text.secondary'>{props.header}</Typography>
    <Stack spacing={props.spacing || 4}>{props.children}</Stack>
  </Box>
);

SectionGroup.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.any,
  spacing: PropTypes.number,
};

export default SectionGroup;
