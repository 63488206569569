import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { usePatchInstallationNodeMutation, useDeleteInstallationNodeMutation } from '../api/package-manager-api';
import Section from './section';
import Setting from './setting';

const Node = ({ node }) => {
  const [nodeSettings, setNodeSettings] = useState({
    name: node.name,
    type: node.type,
  });
  const [saveDisabled, setSaveDisabled] = useState(true);

  const [patchNode] = usePatchInstallationNodeMutation();
  const [deleteNode] = useDeleteInstallationNodeMutation();

  const settingChanged = (name, value) => {
    const settings = nodeSettings;
    settings[name] = value;
    setNodeSettings(settings);
    setSaveDisabled(false);
  };

  const saveSettings = () => {
    patchNode({
      id: node.installationId,
      nodeId: node.id,
      patch: [
        {
          op: 'replace',
          path: 'name',
          value: nodeSettings.name,
        },
        {
          op: 'replace',
          path: 'type',
          value: nodeSettings.type,
        },
      ],
    });

    setSaveDisabled(true);
  };

  return (
    <Section>
      <Setting
        setting={{
          type: 'String',
          prompt: 'Name',
          name: 'name',
          value: nodeSettings.name,
        }}
        onChange={settingChanged}
      />
      <Setting
        setting={{
          type: 'readonly',
          prompt: 'Type',
          value: nodeSettings.type,
        }}
      />
      <Stack direction='row' justifyContent='flex-end'>
        <Button
          variant='contained'
          sx={{ backgroundColor: 'red', minWidth: '10em', marginRight: 4 }}
          onClick={() => deleteNode({ id: node.installationId, nodeId: node.id })}>
          Delete
        </Button>
        <Button
          variant='contained'
          disabled={saveDisabled}
          sx={{ minWidth: '10em' }}
          onClick={() => saveSettings()}>
          Save
        </Button>
      </Stack>
    </Section>
  );
};

Node.propTypes = {
  node: PropTypes.object.isRequired,
};

export default Node;
