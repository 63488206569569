import {
  Link as RouterLink,
} from 'react-router-dom';
import {
  Box, Button, Link, Paper,
  Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Typography,
} from '@mui/material';
import { useGetInstallationsQuery } from '../features/api/package-manager-api';
import loadingOrError from '../features/api/loading-or-error';
import Authorization from '../features/api/authorization';

const InstallationList = () => {
  const { data: installations, error } = useGetInstallationsQuery();
  if (!installations) return loadingOrError(error);

  return (
    <Box>
      <Typography variant='h1' noWrap>AutoStore Installations</Typography>
      <Authorization role="release-manager">
        <Box sx={{ display: 'flex', justifyContent: 'end', paddingBottom: 2 }}>
          <Button sx={{ width: '24ex' }} variant='contained' href='new'>+ Add installation</Button>
        </Box>
      </Authorization>
      <TableContainer component={Paper} elevation={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Installation name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {installations.map((installation) => (
              <TableRow hover key={installation.id}>
                <TableCell>
                  <Link component={RouterLink} to={installation.id}>
                    <Typography noWrap>{installation.displayName}</Typography>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InstallationList;
