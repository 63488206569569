import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { apiAudience } from './package-manager-api';

/**
 * Hook for checking current user roles.
 * @param {string} role Pipe separated string of role(s)
 * @returns {boolean}   True if the user has at least one of the provided roles
 */
export const useRoleCheck = (role) => {
  const auth = useSelector((s) => s.auth);
  // Roles added to the user's idToken in Auth0 Login Flow, as a domain namespaced customClaim.
  const userRoles = (auth?.user?.[`${apiAudience}roles`] ?? []);
  return role.split('|').filter((r) => userRoles.includes(r)).length > 0;
};

const Authorization = ({ role, children, fallback }) => (
  useRoleCheck(role)
    ? <>{children}</>
    : <>{fallback}</>
);

Authorization.propTypes = {
  role: PropTypes.string.isRequired,
  children: PropTypes.node,
  fallback: PropTypes.node,
};

export default Authorization;
