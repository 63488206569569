import { createSlice, isRejectedWithValue } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null },
  reducers: {
    /* eslint-disable no-param-reassign */
    setAuthenticated: (state, { payload: { user, token } }) => {
      state.user = user;
      state.token = token;
    },
    clearAuthenticated: (state) => {
      state.user = null;
      state.token = null;
    },
    /* eslint-enable no-param-reassign */
  },
});

export const { setAuthenticated, clearAuthenticated } = slice.actions;

const authCatchUnauthenticated = ({ dispatch }) => (next) => (action) => {
  if (isRejectedWithValue(action) && action.payload.status === 401) {
    dispatch(clearAuthenticated());
  }
  return next(action);
};

export default {
  reducer: slice.reducer,
  middleware: authCatchUnauthenticated,
};
