import { useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Box, Button, Grid, Link, Stack, SvgIcon, Typography,
} from '@mui/material';
import {
  useGetInstallationQuery,
  useGetInstallationStatusQuery,
  usePutInstallationManifestMutation,
  useLazyDownloadInstallerQuery,
} from '../features/api/package-manager-api';
import Authorization from '../features/api/authorization';
import Section from '../features/installation/section';
import SectionGroup from '../features/installation/section-group';
import AuditLog from '../features/installation/audit-log';
import { Bundle, isEqualBundle } from '../features/installation/bundle';
import New from '../features/installation/new';
import loadingOrError from '../features/api/loading-or-error';
import { ReactComponent as settingsIcon } from '../assets/settings.svg';
import BackLink from '../features/ui/back-link';

function bundleKey(bundle) {
  return bundle ? `${bundle.name}/${bundle.version}` : '';
}

function isRunning(installationStatus) {
  return installationStatus?.status === 'Running';
}

const Installation = () => {
  const { id } = useParams();
  const { data: installation, error } = useGetInstallationQuery(id);
  const statusQuery = useGetInstallationStatusQuery(id);

  const [putManifest, putManifestResult] = usePutInstallationManifestMutation();
  const [downloadInstaller] = useLazyDownloadInstallerQuery();

  useEffect(() => {
    const prevTitle = document.title;
    if (installation) {
      document.title = `${installation.displayName} - Cube Control Update`;
    }
    // As long as we are waiting for Supervisor to pick up the new bundle,
    // poll every 10 seconds.
    if (statusQuery.isSuccess && !isRunning(statusQuery.data)) {
      setTimeout(() => statusQuery.refetch(), 10000);
    }
    return () => {
      document.title = prevTitle;
    };
  });

  const updateManifest = (bundleRef) => {
    putManifest({
      id,
      data: { name: bundleRef.name, version: bundleRef.version },
    });
  };

  const availableBundles = installation?.availableBundles
    ?.filter((bundle) => bundle && !isEqualBundle(bundle, installation.manifest?.bundle))
    ?.reverse();

  if (!installation) {
    if (error?.status === 404) {
      return (
        <Authorization role='release-manager' fallback={loadingOrError(error)}>
          <New id={id} />
        </Authorization>
      );
    }
    return loadingOrError(error);
  }
  return (
    <Stack>
      <BackLink to='/installations' />
      <Grid container>
        <Grid item xs={12} md={9}>
          <Typography variant="h1" gutterBottom>{installation.displayName}</Typography>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={12} md={3}>
          <Authorization role='commissioning'>
            <Link to='config' component={RouterLink}>
              <Button variant='contained' sx={{ minWidth: '18ex' }} startIcon={<SvgIcon component={settingsIcon} inheritViewBox />}>Configure</Button>
            </Link>
          </Authorization>
        </Grid>
      </Grid>
      {(!installation.manifest?.bundle && installation.installer?.version)
        && <Authorization role='commissioning'>
          <SectionGroup header="">
            <Section>
              <center>
                <Button
                  variant='contained'
                  onClick={() => downloadInstaller(installation.id)}>
                  Download AutoStore Cube Control System Installer
                </Button>
              </center>
            </Section>
          </SectionGroup>
        </Authorization>
      }
      {(installation.manifest?.bundle?.version)
        && <SectionGroup header="Current bundle">
          <Bundle
            key={bundleKey(installation.manifest.bundle)}
            bundle={installation.manifest.bundle}
            isCurrent={true}
            isRunning={isRunning(statusQuery.data)}
            installation={installation}
            updateManifest={updateManifest}
          />
        </SectionGroup>
      }
      {availableBundles?.length > 0
        && <SectionGroup header="Available bundles">
          {availableBundles.map((bundle) => (
            <Bundle
              key={bundleKey(bundle)}
              bundle={bundle}
              installation={installation}
              updateManifest={updateManifest}
            />
          ))}
        </SectionGroup>
      }
      <SectionGroup header='Log'>
        <AuditLog installationId={id} />
      </SectionGroup>
      {(putManifestResult.isError)
        && <Box><h1 className="display-6">Updating bundle failed!</h1>
          {putManifestResult.data
            ? putManifestResult.data.title
            : `${putManifestResult.status} (${putManifestResult.error?.status})`}
        </Box>
      }
    </Stack>
  );
};

export default Installation;
