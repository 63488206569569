import { Link as RouterLink } from 'react-router-dom';
import {
  Box, Link, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography,
} from '@mui/material';
import { useGetLatestPackagesQuery } from '../features/api/package-manager-api';
import loadingOrError from '../features/api/loading-or-error';

const PackageList = () => {
  const { data: packages, error } = useGetLatestPackagesQuery({ includePrerelease: true });
  if (!packages) return loadingOrError(error);

  return (
    <Box>
      <Typography variant='h1' noWrap gutterBottom sx={{ paddingBottom: '2ex' }}>Packages</Typography>
      <TableContainer component={Paper} elevation={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Display&nbsp;Name</TableCell>
              <TableCell>Latest</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages.map((pkg) => (
              <TableRow hover key={`${pkg.name}/${pkg.version}`}>
                <TableCell>
                  <Link component={RouterLink} to={`${pkg.name}/${pkg.version}`}>
                    {pkg.name}
                  </Link>
                </TableCell>
                <TableCell>{pkg.displayName}</TableCell>
                <TableCell>{pkg.version}</TableCell>
                <TableCell>{new Date(pkg.createdAt).toLocaleDateString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PackageList;
