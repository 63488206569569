import PropTypes from 'prop-types';
import {
  NavLink,
  useLocation,
} from 'react-router-dom';
import {
  Box, Drawer, Link,
  List, ListItem, ListItemIcon, ListItemButton, ListItemText,
  Typography,
} from '@mui/material';
import logo from '../../assets/logo.svg';

const sidebarWidth = 240;

const Sidebar = (props) => (
  <Drawer
    variant='permanent'
    sx={{
      width: sidebarWidth,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: sidebarWidth, boxSizing: 'border-box', borderWidth: 0, marginTop: '68px',
      },
    }}>{props.children}</Drawer>
);

Sidebar.propTypes = {
  children: PropTypes.any,
};

const SidebarLinks = (props) => (
  <Box sx={{ overflow: 'auto' }}>
    <List>{props.children}</List>
  </Box>
);

SidebarLinks.propTypes = {
  children: PropTypes.any,
};

const SidebarLink = (props) => {
  const location = useLocation();
  const selected = location.pathname.startsWith(props.to);
  return (
    <Link component={NavLink} to={props.to}>
      <ListItem>
        <ListItemButton selected={selected}>
          <ListItemIcon><props.icon /></ListItemIcon>
          <ListItemText><Typography sx={{ fontWeight: 'bold' }}>{props.title}</Typography></ListItemText>
        </ListItemButton>
      </ListItem>
    </Link>
  );
};

SidebarLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const SidebarFooter = () => (
  <Box display='flex' justifyContent='center' alignItems='center' sx={{
    position: 'fixed',
    bottom: 0,
    width: sidebarWidth,
    paddingBottom: 0,
  }}>
    <List>
      <ListItem>
        <ListItemIcon>
          <img
            src={logo}
            alt="AutoStore Logo"
            width="30"
            height="30"
          />
        </ListItemIcon>
        <ListItemText>
          <Typography sx={{ fontWeight: 'bold' }}>AutoStore</Typography>
        </ListItemText>
      </ListItem>
    </List>
  </Box>
);

Sidebar.Links = SidebarLinks;
Sidebar.Link = SidebarLink;
Sidebar.Footer = SidebarFooter;

export default Sidebar;
