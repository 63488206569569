import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Link, Typography,
} from '@mui/material';

const BundleContentsItem = ({
  type, name, version, displayName,
}) => (
  <Link to={`/${type}/${name}/${version}`} component={RouterLink}>
    <Typography>{displayName} - version&nbsp;{version}</Typography>
  </Link>
);

const MapListItems = (list, type) => (
  list.map((c) => (
    <li key={`${type}/${c.name}/${c.version}`}>
      <BundleContentsItem
        type={type} name={c.name} version={c.version} displayName={c.displayName} />
    </li>
  ))
);

const BundleContentsList = ({ className, bundles, packages }) => (
  <ul className={className}>
    {MapListItems(bundles, 'bundles')}
    {MapListItems(packages, 'packages')}
  </ul>
);

BundleContentsItem.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
};

BundleContentsList.propTypes = {
  bundles: PropTypes.array.isRequired,
  packages: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default BundleContentsList;
