import PropTypes from 'prop-types';
import { Stack, Box } from '@mui/material';

const Problem = ({ message, status }) => (
  <Stack justifyContent='center' alignItems='center' minHeight='80vh'>
    <p>Terribly sorry, but...</p>
    <h1>
      {message}
      <Box component='span' sx={{ color: '#6c757d' }}>&nbsp;|&nbsp;{status}</Box>
    </h1>
  </Stack>
);

Problem.propTypes = {
  message: PropTypes.string,
  status: PropTypes.number,
};

export default Problem;
