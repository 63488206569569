export const updateSet = (set, item, key = 'name') => {
  const newSet = [...set];
  const ix = newSet.findIndex((i) => i[key] === item[key]);
  if (ix !== -1) {
    newSet.splice(ix, 1, item);
  } else {
    newSet.push(item);
  }
  return newSet;
};

export default updateSet;
