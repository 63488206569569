import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Avatar, IconButton, Menu, MenuItem, Stack, Typography,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const UserMenu = () => {
  const { logout } = useAuth0();
  const auth = useSelector((s) => s.auth);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  // User's initials, restricted to max 2 (first and last).
  const initials = auth?.user?.name?.split(' ').map(
    (n, i, a) => (i === 0 || i === a.length - 1 ? n[0] : null),
  );

  const showMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  if (!auth?.user) return <span />;
  return (
    <Stack direction='row' alignItems='center' sx={{ marginLeft: 'auto' }}>
      <Typography justifySelf='center' variant='subtitle2'>Welcome, {auth.user.name}</Typography>

      <IconButton
        aria-label='account of current user'
        aria-controls='user-menu'
        aria-haspopup='true'
        onClick={showMenu}>
        <Avatar sx={{ height: 36, width: 36 }}>{initials}</Avatar>
      </IconButton>
      <Menu
        id='user-menu'
        anchorEl={menuAnchorEl}
        open={menuAnchorEl != null}
        onClose={closeMenu}>
        <MenuItem onClick={() => logout({
          returnTo: window.location.origin,
        })}
        >Logout</MenuItem>
      </Menu>
    </Stack>
  );
};

export default UserMenu;
