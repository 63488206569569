import { createTheme, alpha } from '@mui/material';

export default function getTheme() {
  return createTheme({
    shape: {
      borderRadius: 8,
    },
    palette: {
      primary: {
        main: '#276FEC',
      },
      secondary: {
        main: '#ffffff',
      },
      background: {
        default: '#f0f0f0',
      },
      accent: {
        light: '#EFF3FE', // accent-light, "Blue sites"
        main: '#276FEC',
        dark: '#185BD1',
      },
      success: {
        light: '#E6F2ED', // success-light, "Green sites"
        main: '#15844B',
        dark: '#0E733F',
      },
      error: {
        light: '#FFEFED', // error-light, "Red sites"
        main: '#E11B22',
        dark: '#C91218',
      },
      warning: {
        main: '#FFC043',
        dark: '#F0B135',
      },
      subdued: {
        main: '#d5d5d5',
      },
    },
    typography: {
      fontFamily: 'CircularXX',
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
            '&:hover': {
              color: 'white',
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#EFF3FE',
              borderRadius: 8,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none',
            color: 'inherit',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            marginBottom: '2ex',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: 'none',
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 8,
            paddingBottom: 8,
          },
          head: {
            color: alpha('#000000', 0.6),
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: 24,
            fontWeight: 'bold',
          },
        },
      },
    },
  });
}
