import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { parse } from 'content-disposition-header';

export const apiAudience = 'https://cubecontrol.autostoresystem.com/';
export const apiScope = 'openid profile read:installations update:installations read:packages read:bundles';

const queryString = (params) => new URLSearchParams(params).toString();

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_HOST}`,
  prepareHeaders: async (headers, { getState }) => {
    headers.set('Authorization', `Bearer ${getState().auth.token}`);
    return headers;
  },
});

const packageManagerApi = createApi({
  reducerPath: 'packageManagerApi',
  baseQuery,
  tagTypes: ['installation', 'package', 'bundle'],
  endpoints: (build) => ({
    getInstallations: build.query({
      query: () => 'installations',
      transformResponse: (response) => response.installations,
      providesTags: (result = []) => [
        { type: 'installation', id: 'list' },
        ...result.map(({ id }) => ({ type: 'installation', id })),
      ],
    }),
    getInstallation: build.query({
      query: (id) => `installations/${id}`,
      providesTags: (result, error, id) => [{ type: 'installation', id }],
    }),
    getInstallationAuditLog: build.query({
      query: ({ installationId, limit = 100 }) => (
        `installations/${installationId}/auditLog?${queryString({ limit })}`
      ),
    }),
    getInstallationNodes: build.query({
      query: (id) => `installations/${id}/nodes`,
      providesTags: (result, error, id) => [{ type: 'installationNodes', id }],
    }),
    getInstallationSettings: build.query({
      query: (id) => `installations/${id}/settings`,
    }),
    getInstallationStatus: build.query({
      query: (id) => `installations/${id}/status`,
      providesTags: (result, error, id) => [{ type: 'installation', id }],
    }),
    postInstallation: build.mutation({
      query: (data) => ({
        url: '/installations',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: 'installation', id: 'list' },
        // Invalidate the newly created object, since a 404 response might be in the cache.
        { type: 'installation', id: data.id }],
    }),
    addAvailableBundle: build.mutation({
      query: ({ installationId, name, version }) => ({
        url: `/installations/${installationId}/bundles`,
        method: 'POST',
        body: { name, version },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'installation', id: arg.installationId }],
    }),
    removeAvailableBundle: build.mutation({
      query: ({ installationId, name, version }) => ({
        url: `/installations/${installationId}/bundles/${name}/${version}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'installation', id: arg.installationId }],
    }),
    patchInstallationNode: build.mutation({
      query: ({ id, nodeId, patch }) => ({
        url: `installations/${id}/nodes/${nodeId}`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json-patch+json' },
        body: patch,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'installationNodes', id: arg.id }],
    }),
    deleteInstallationNode: build.mutation({
      query: ({ id, nodeId }) => ({
        url: `installations/${id}/nodes/${nodeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'installationNodes', id: arg.id }],
    }),
    patchInstallationSettings: build.mutation({
      query: ({ id, ...op }) => ({
        url: `/installations/${id}/settings`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json-patch+json' },
        body: [op],
      }),
    }),
    putInstallationManifest: build.mutation({
      query: ({ id, data }) => ({
        url: `/installations/${id}/manifest`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'installation', id: arg.id }],
    }),
    getLatestPackages: build.query({
      query: ({ includePrerelease = false }) => `packages?version=latest&includePrerelease=${includePrerelease}`,
      transformResponse: (response) => response.packages,
      providesTags: (result = []) => [
        { type: 'package', id: 'list' },
        ...result.map((pkg) => ({ type: 'package', id: `${pkg.name}/${pkg.version}` })),
      ],
    }),
    getPackage: build.query({
      query: ({ name, version }) => `packages/${name}/${version}`,
      providesTags: (result = []) => [
        { type: 'package', id: `${result.name}/${result.version}` },
      ],
    }),
    getPackageVersions: build.query({
      query: ({ name, includePrerelease = false }) => `packages?name=${name}&orderBy=version&descending=true&includePrerelease=${includePrerelease}`,
      transformResponse: (response) => response.packages,
      providesTags: (result = []) => [
        ...result.map((pkg) => ({ type: 'package', id: `${pkg.name}/${pkg.version}` })),
      ],
    }),
    setPackageArchived: build.mutation({
      query: ({ name, version }) => ({
        url: `/packages/${name}/${version}/archived`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: { value: true },
      }),
      invalidatesTags: (result, error, data) => [
        { type: 'package', id: 'list' },
        { type: 'package', id: data.id }],
    }),
    unSetPackageArchived: build.mutation({
      query: ({ name, version }) => ({
        url: `/packages/${name}/${version}/archived`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: { value: false },
      }),
      invalidatesTags: (result, error, data) => [
        { type: 'package', id: 'list' },
        { type: 'package', id: data.id }],
    }),
    getLatestBundles: build.query({
      query: ({ category } = { category: '' }) => (
        `bundles?${queryString({ version: 'latest', category })}`
      ),
      transformResponse: (response) => response.bundles,
      providesTags: (result = []) => [
        { type: 'bundle', id: 'list' },
        ...result.map((bundle) => ({ type: 'bundle', id: `${bundle.name}/${bundle.version}` })),
      ],
    }),
    getBundle: build.query({
      query: ({ name, version }) => `bundles/${name}/${version}`,
      providesTags: (result = []) => [
        { type: 'bundle', id: `${result.name}/${result.version}` },
      ],
    }),
    getBundleVersions: build.query({
      query: ({ name }) => `bundles?name=${name}&orderBy=version&descending=true`,
      transformResponse: (response) => response.bundles,
      providesTags: (result = []) => [
        ...result.map((bundle) => ({ type: 'bundle', id: `${bundle.name}/${bundle.version}` })),
      ],
    }),
    postBundle: build.mutation({
      query: (data) => ({
        url: '/bundles',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        { type: 'bundle', id: 'list' },
        // Invalidate the newly created object, since a 404 response might be in the cache.
        { type: 'bundle', id: data.id }],
    }),
    archiveBundle: build.mutation({
      query: ({ name, version }) => ({
        url: `/bundles/${name}/${version}/archived`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: { value: true },
      }),
      invalidatesTags: (result, error, data) => [
        { type: 'bundle', id: 'list' },
        { type: 'bundle', id: data.id }],
    }),
    unArchiveBundle: build.mutation({
      query: ({ name, version }) => ({
        url: `/bundles/${name}/${version}/archived`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: { value: false },
      }),
      invalidatesTags: (result, error, data) => [
        { type: 'bundle', id: 'list' },
        { type: 'bundle', id: data.id }],
    }),
    downloadInstaller: build.query({
      // "Abuse" RTK Query - we use it to avoid duplicating the authentication part,
      // but at the same time want to skip its inherent caching.
      // Download the binary from the backend into a blob, returning an empty object to RTK Query.
      // Clicking a hidden link to the blob triggers the download to the client.
      queryFn: async (id, api, extraOptions, _baseQuery) => {
        const result = await _baseQuery({
          url: `installations/${id}/installer`,
          responseHandler: ((response) => response.blob()),
        });
        const disposition = parse(result?.meta?.response?.headers?.get('content-disposition'));
        const hiddenElement = document.createElement('a');
        const blob = window.URL.createObjectURL(result.data);
        hiddenElement.href = blob;
        hiddenElement.target = '_blank';
        hiddenElement.download = disposition?.parameters?.filename || 'AutoStore Cube Control.exe';
        hiddenElement.click();
        // Ensure we don't leak memory (otherwise blob is kept in memory until page is refreshed)
        window.setTimeout(() => window.URL.revokeObjectURL(blob), 0);
        return { data: null };
      },
      cache: 'no-cache',
    }),
  }),
});

export const {
  useGetInstallationsQuery,
  useGetInstallationQuery,
  useGetInstallationAuditLogQuery,
  useGetInstallationNodesQuery,
  useGetInstallationSettingsQuery,
  useGetInstallationStatusQuery,
  usePostInstallationMutation,
  useAddAvailableBundleMutation,
  useRemoveAvailableBundleMutation,
  usePatchInstallationNodeMutation,
  useDeleteInstallationNodeMutation,
  usePatchInstallationSettingsMutation,
  usePutInstallationManifestMutation,
  useGetPackageQuery,
  useGetPackageVersionsQuery,
  useLazyGetPackageVersionsQuery,
  useGetLatestPackagesQuery,
  useLazyGetLatestPackagesQuery,
  useGetLatestBundlesQuery,
  useLazyGetLatestBundlesQuery,
  useSetPackageArchivedMutation,
  useUnSetPackageArchivedMutation,
  useGetBundleQuery,
  useLazyGetBundleQuery,
  useGetBundleVersionsQuery,
  useLazyGetBundleVersionsQuery,
  usePostBundleMutation,
  useArchiveBundleMutation,
  useUnArchiveBundleMutation,
  useLazyDownloadInstallerQuery,
} = packageManagerApi;

export default {
  reducerPath: packageManagerApi.reducerPath,
  reducer: packageManagerApi.reducer,
  middleware: packageManagerApi.middleware,
};
