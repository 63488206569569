import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Link, Typography,
} from '@mui/material';

const BackLink = ({ text, to }) => {
  const navigate = useNavigate();
  const goBack = (e) => {
    e.preventDefault();
    navigate(-1);
    // If navigating back fails (typically, new window), go up one level.
    // 'to' is basically a workaround for '.' seemingly not working as expected at the moment.
    navigate(to || '.');
  };
  return (
    <Typography variant='subtitle1' gutterBottom sx={{ marginBottom: '2ex' }}>
      <Link sx={{ cursor: 'pointer' }} onClick={(e) => goBack(e)}>&lt;&nbsp;{text || 'Back'}</Link>
    </Typography>
  );
};

BackLink.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
};

export default BackLink;
