import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert, AlertTitle, Button, FormControl, Grid, MenuItem, Select, Stack, Typography,
} from '@mui/material';
import {
  useGetInstallationQuery,
  useGetInstallationNodesQuery,
  useGetInstallationSettingsQuery,
  useGetLatestBundlesQuery,
  useGetBundleVersionsQuery,
  useAddAvailableBundleMutation,
  useRemoveAvailableBundleMutation,
  usePatchInstallationSettingsMutation,
  useLazyDownloadInstallerQuery,
} from '../features/api/package-manager-api';
import Authorization from '../features/api/authorization';
import { isEqualBundle } from '../features/installation/bundle';
import SectionGroup from '../features/installation/section-group';
import Node from '../features/installation/node';
import Setting from '../features/installation/setting';
import loadingOrError from '../features/api/loading-or-error';
import minusIcon from '../assets/minus.svg';
import plusIcon from '../assets/plus.svg';
import BackLink from '../features/ui/back-link';
import Section from '../features/installation/section';

const InstallationConfig = () => {
  const { id } = useParams();
  const [addName, setAddName] = useState('');
  const [addVersion, setAddVersion] = useState('');
  const { data: installation, error } = useGetInstallationQuery(id);
  const { data: installationNodes } = useGetInstallationNodesQuery(id);
  const { data: latestBundles } = useGetLatestBundlesQuery();
  const { data: versions } = useGetBundleVersionsQuery({
    name: addName,
  }, { skip: addName == null });
  const [addAvailableBundle, addBundleResult] = useAddAvailableBundleMutation();
  const [removeAvailableBundle] = useRemoveAvailableBundleMutation();
  const [downloadInstaller] = useLazyDownloadInstallerQuery();

  const selectBundle = (event, latest) => {
    const name = event.target.value;
    const latestVersion = latest.find((o) => o.name === name);
    setAddName(name);
    if (latestVersion?.version) setAddVersion(latestVersion.version);
  };
  const selectVersion = (event) => {
    setAddVersion(event.target.value);
  };

  const addBundle = () => addAvailableBundle({
    installationId: id, name: addName, version: addVersion,
  });
  const removeBundle = (name, version) => removeAvailableBundle({
    installationId: id, name, version,
  });

  useEffect(() => {
    if (installation) {
      document.title = `${installation.displayName} - Cube Control Update`;
    }
  });

  const { data: installationSettings } = useGetInstallationSettingsQuery(id);
  const settings = installationSettings?.settings;
  const [changedSettings, setChangedSettings] = useState({});
  const [saveDisabled, setSaveDisabled] = useState(true);

  const settingChanged = (name, value) => {
    const cs = changedSettings;
    cs[name] = value;
    setChangedSettings(cs);
    setSaveDisabled(false);
  };

  const [patchInstallationSettings, patchSettingsResult] = usePatchInstallationSettingsMutation();

  const onSaveSettings = (() => {
    const values = Object.entries(changedSettings).map(
      ([key, val]) => ({ name: key, value: val }),
    );

    const patch = {
      id,
      op: 'replace',
      path: 'settings',
      value: values,
    };

    patchInstallationSettings(patch);
    setChangedSettings({});
    setSaveDisabled(true);
  });
  /* eslint quotes: [0, "double"] */
  const kibanaUrl = "https://kibana.cubecontrol.autostoresystem.com/app/discover#/"
    + "?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1h,to:now))"
    + "&_a=(columns:!(fields.InstallationId,fields.SourceContext,level,message),"
    + "filters:!(('$state':(store:appState),"
    + `query:(match_phrase:(fields.InstallationId:${id})))),`
    + "hideChart:!t,interval:auto,query:(language:kuery,query:''),sort:!(!('@timestamp',desc)))";

  if (!installation) return loadingOrError(error);
  return (
    <Stack>
      <BackLink to={`/installations/${id}`} />
      <Typography variant="h1" gutterBottom>{installation.displayName}</Typography>
      <SectionGroup header='Available bundles'>
        <Section>
          <Stack direction='column' alignItems='center' spacing={1}>
            {installation.availableBundles && installation.availableBundles.length > 0
              && [...installation.availableBundles].reverse().map((bundle) => (
                <Grid container item sx={{ maxWidth: '30em' }} key={`${bundle.name}/${bundle.version}`}>
                  <Button variant='outlined' disabled sx={{
                    minWidth: '16em', flexGrow: 4, borderTopRightRadius: 0, borderBottomRightRadius: 0,
                  }}>{bundle.name}</Button>
                  <Button variant='outlined' disabled sx={{
                    minWidth: '10em', borderRadius: 0, borderLeftWidth: 0, borderRightWidth: 0,
                  }}>{bundle.version}</Button>
                  <Button
                    variant='contained' color='error'
                    sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    disabled={isEqualBundle(bundle, installation.manifest?.bundle)}
                    onClick={() => removeBundle(bundle.name, bundle.version)}>
                    <img width={8} height={8} src={minusIcon} alt='Remove' />
                  </Button>
                </Grid>
              ))}
            <Grid container item sx={{ maxWidth: '30em' }}>
              <FormControl size='small' sx={{ minWidth: '12em', flexGrow: 4 }}>
                <Select
                  sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                  displayEmpty
                  renderValue={() => (addName === '' ? 'Add...' : addName)}
                  value={addName}
                  onChange={(e) => selectBundle(e, latestBundles)}>
                  {latestBundles?.map((p) => (
                    <MenuItem
                      value={`${p.name}`}
                      key={`${p.name}`}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size='small' sx={{ minWidth: '9em' }}>
                <Select
                  sx={{ borderRadius: 0, borderLeftWidth: 0, borderRightWidth: 0 }}
                  displayEmpty
                  renderValue={() => (addVersion === '' ? '\u00A0' : addVersion)}
                  value={addVersion}
                  onChange={selectVersion}>
                  {versions?.map((p) => (
                    <MenuItem
                      key={p.version}
                      value={p.version}>
                      {p.version}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant='contained'
                disabled={addVersion === ''} onClick={addBundle}
                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                <img src={plusIcon} width={8} height={8} alt='Add' /></Button>
            </Grid>
            {addBundleResult.isError
              && <Grid container item>
                <Alert severity='error' onClose={() => addBundleResult.reset()}>
                  <AlertTitle>Adding bundle failed</AlertTitle>
                  {(addBundleResult?.error?.data?.errors)
                    ? Object.keys(addBundleResult.error.data.errors).map((k) => (
                      <p key={k}>{addBundleResult.error.data.errors[k]}</p>
                    ))
                    : JSON.stringify(addBundleResult)}
                </Alert>
              </Grid>
            }
          </Stack>
        </Section>
      </SectionGroup>
      <Authorization role='commissioning'>
        {settings
          && <SectionGroup header="Configuration">
            <Section>
              {
                settings.map((setting) => (
                  <Setting
                    key={setting.name}
                    setting={setting}
                    onChange={settingChanged}
                  />
                ))
              }
              <Stack direction='row' justifyContent='flex-end'>
                <Button
                  variant='contained'
                  disabled={saveDisabled}
                  sx={{ minWidth: '10em' }}
                  onClick={() => onSaveSettings()}>
                  Save
                </Button>
              </Stack>
            </Section>
            <Stack>
              {patchSettingsResult.isError
                && <Alert severity='error' onClose={() => patchSettingsResult.reset()}>
                  <AlertTitle>Saving settings failed</AlertTitle>
                  {(patchSettingsResult?.error?.data?.errors)
                    ? Object.keys(patchSettingsResult.error.data.errors).map((k) => (
                      <p key={k}>{patchSettingsResult.error.data.errors[k]}</p>
                    ))
                    : JSON.stringify(patchSettingsResult)}
                </Alert>
              }
              {patchSettingsResult.isLoading
                && <Alert severity='info' onClose={() => patchSettingsResult.reset()}>
                  <AlertTitle>Saving settings...</AlertTitle>
                </Alert>
              }
              {patchSettingsResult.isSuccess
                && <Alert severity='success' onClose={() => patchSettingsResult.reset()}>
                  <AlertTitle>Settings saved</AlertTitle>
                </Alert>
              }
            </Stack>
          </SectionGroup>
        }
      </Authorization>
      <SectionGroup header="Operation">
        <Section>
          <a href={kibanaUrl} target='_new'>View logs for InstallationId:{id}</a>
        </Section>
      </SectionGroup>
      <Authorization role='commissioning'>
        <SectionGroup header="Commissioning">
          <Section>
            <center>
              <Button
                variant='contained'
                onClick={() => downloadInstaller(installation.id)}>
                Download AutoStore Cube Control System Installer
              </Button>
            </center>
          </Section>
        </SectionGroup>
        {installationNodes?.nodes?.length > 0
          ? <SectionGroup header="Nodes">
            {installationNodes.nodes.map((node) => (
              <Node
                key={node.id}
                node={node}
              />
            ))}
          </SectionGroup>
          : null
        }
      </Authorization>
    </Stack>
  );
};

export default InstallationConfig;
