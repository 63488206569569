import { configureStore } from '@reduxjs/toolkit';
import packageManagerApi from './features/api/package-manager-api';
import auth from './features/api/auth-slice';

const store = configureStore({
  reducer: {
    [packageManagerApi.reducerPath]: packageManagerApi.reducer,
    auth: auth.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    packageManagerApi.middleware,
    auth.middleware,
  ]),
});

export default store;
