import PropTypes from 'prop-types';
import {
  AppBar, Box, Toolbar,
} from '@mui/material';
import logo from '../../assets/logo.svg';

const Layout = (props) => (
  <Box sx={{ display: 'flex' }}>{props.children}</Box>
);

Layout.propTypes = {
  children: PropTypes.any,
};

const Header = (props) => (
  <AppBar color='secondary' position='fixed' elevation={0} sx={{ borderBottom: '4px solid #f0f0f0', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    <Toolbar>
      {props.children}
    </Toolbar>
  </AppBar>

);

Header.propTypes = {
  children: PropTypes.any,
};

const Body = (props) => (
  <Box component='main' justifyContent={'center'} alignItems='center' sx={{
    flexGrow: 1, paddingX: 12, paddingY: 4, marginX: 'auto',
  }}>
    <Toolbar />
    {props.children}
  </Box>
);

const DefaultBody = () => (
  <Box className="App-header">
    <img src={logo} className="App-logo" alt="logo" />
  </Box>
);

Body.propTypes = {
  children: PropTypes.any,
};

Layout.Header = Header;
Layout.Body = Body;
Layout.DefaultBody = DefaultBody;
export default Layout;
