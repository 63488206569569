import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Alert, AlertTitle, Button, Stack, TextField,
} from '@mui/material';
import { usePostInstallationMutation } from '../api/package-manager-api';

const New = ({ id }) => {
  const navigate = useNavigate();
  const [installationId, setInstallationId] = useState(id !== 'new' ? id : '');
  const [installationName, setInstallationName] = useState('');
  const [postInstallation, postResult] = usePostInstallationMutation();

  const createInstallation = () => {
    postInstallation({ id: installationId, displayName: installationName });
  };
  if (postResult.isSuccess) {
    navigate(`/installations/${installationId}`);
  }
  const requestErrorData = postResult.error?.data;
  return (
    <Stack justifyContent='center' alignItems='center' spacing={2}>
      <h1>Initialize installation for Package Manager</h1>
      <TextField
        size='medium'
        type='text'
        label='Installation Id'
        placeholder='SalesForce Installation Id'
        sx={{ width: '48ex' }}
        value={installationId}
        onChange={(e) => setInstallationId(e.target.value)}>
      </TextField>
      <TextField
        size='medium'
        type='text'
        label='Display Name'
        placeholder='Display Name'
        sx={{ width: '48ex' }}
        value={installationName}
        onChange={(e) => setInstallationName(e.target.value)}>
      </TextField>
      <Button
        disabled={installationId === '' || installationName === ''}
        variant='contained'
        sx={{ width: '24ex' }}
        onClick={createInstallation}>
        Confirm
      </Button>
      {(postResult.isError)
        && <Alert severity='error'>
          <AlertTitle>Adding installation failed: {requestErrorData.title}</AlertTitle>
          <p>{requestErrorData.detail}</p>
          {(requestErrorData?.errors)
            ? Object.keys(requestErrorData.errors).map((k) => (
              <p key={k}>{requestErrorData.errors[k]}</p>
            ))
            : JSON.stringify(requestErrorData)}
        </Alert>}
    </Stack>
  );
};

New.propTypes = {
  id: PropTypes.string.isRequired,
};

export default New;
